import React, { useCallback, useMemo, useState } from 'react'
import { Button, Typography, useTheme } from '@mui/material'
import type { NavPointCallbacksT } from 'components/Page/Header/Toolbar/Menu/types'
import { motion } from 'framer-motion'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useRolesViews from 'hooks/useRolesViews'
import useSessionContext from 'hooks/useSessionContext'
import { useLocation, useHistory } from 'react-router'
import { useSetDatadogUser } from 'services/Datadog/useSetDatadogUser'
import routes from 'services/RoutesProvider/routes'
import { isNative } from 'utils/platform'
import { Browser } from '@capacitor/browser'
import { datadogLogs } from '@datadog/browser-logs'
import { BUTTON_GROUP_MOTION, BUTTON_ITEM_MOTION } from './motions'
import { ButtonGroup, NavigationButtonsContainer } from './styles'
import type { NavigationButtonProps } from './types'

const NavigationButtons: React.FC<NavigationButtonProps> = ({ toggleDrawer, navPoints }) => {
  // TODO: refactor
  const { t } = useFavurTranslation()
  const theme = useTheme()
  const { pathname } = useLocation()
  const history = useHistory()
  const { clearActiveView } = useRolesViews()
  const { logout: contextLogout } = useSessionContext()
  const [hoverStates, setHoverStates] = useState(Array(navPoints.length).fill(false))
  useSetDatadogUser()

  const logout = useCallback(() => {
    datadogLogs.logger.info('[Doing logout] From TertiaryNavigation')
    clearActiveView()
    contextLogout()
  }, [clearActiveView, contextLogout])

  const closeIfCurrent = useCallback(
    (to: string) => {
      to === pathname && toggleDrawer()
    },
    [pathname, toggleDrawer],
  )

  const onNavigate = useCallback(
    (to: string) => {
      closeIfCurrent(to)
      history.push(to)
    },
    [closeIfCurrent, history],
  )

  const callbacks: NavPointCallbacksT = useMemo(
    () => ({
      contact: () => onNavigate(routes.contact),
      privacy: async () => {
        await Browser.open({ url: t('page.termsAndConditionsUpdate.url.dataProtection') })
      },
      logout,
    }),
    [onNavigate, t, logout],
  )

  const handleHoverStates = (index: number, state: boolean) =>
    setHoverStates((prevState) => {
      const newState = [...prevState]
      newState[index] = state

      return newState
    })

  return (
    <NavigationButtonsContainer isNative={isNative()}>
      <ButtonGroup initial="hidden" animate="visible" variants={BUTTON_GROUP_MOTION}>
        {navPoints.map((navPoint, index) => (
          <motion.li
            variants={BUTTON_ITEM_MOTION(index)}
            onMouseEnter={() => handleHoverStates(index, true)}
            onMouseLeave={() => handleHoverStates(index, false)}
            key={navPoint.id}
          >
            <Button
              key={navPoint.label}
              data-testid={navPoint.testId}
              onClick={callbacks[navPoint.id]}
              disableRipple
              variant="text"
              size="small"
            >
              <Typography
                variant="body2"
                color={hoverStates[index] ? theme.palette.common.white : theme.palette.common.navItemBase}
              >
                {t(navPoint.label)}
              </Typography>
            </Button>
          </motion.li>
        ))}
      </ButtonGroup>
      {/* todo: add app info  */}
      {/* {showInfo && <ApplicationInformation label="App Info" onClose={() => setShowInfo(false)} />} */}
    </NavigationButtonsContainer>
  )
}

export default NavigationButtons
