import React from 'react'
import { Search } from '../Icon'
import { SearchField } from './styles'
import type { SearchInputProps } from './types'

const SearchInput: React.FC<SearchInputProps> = ({ onChange }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(event.target.value)
  }

  return (
    <SearchField
      variant="outlined"
      // Modify with actual label
      label="Search"
      InputProps={{
        endAdornment: <Search fillOpacity="0.6" />,
      }}
      onChange={handleChange}
    />
  )
}

export default SearchInput
