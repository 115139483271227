import { IconButton, styled } from '@mui/material'

export const Footer = styled('footer')`
  display: flex;
  justify-content: end;
  padding: ${({ theme }) => theme.spacing(1.5, 3, 5.5, 3)};
  max-width: ${({ theme }) => theme.spacing(72)};
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
`

export const ExitButton = styled(IconButton)`
  border-radius: ${({ theme }) => theme.spacing(1)};

  &:active {
    background-color: ${({ theme }) => theme.palette.common.hoverNavItem};
  }
`
