import React from 'react'
import { Button, IconButton, Typography, useTheme } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { ChevronLeft } from 'redesign/components/atoms/Icon'
import { ICON_SIZE } from 'redesign/components/atoms/Icon/constants'
import DrawerHeader from 'redesign/components/organisms/DrawerHeader'
import type { FiltersComponentsProps } from './types'

const FiltersHeaders: React.FC<FiltersComponentsProps> = ({ isPersonsFilter, personsFilterOnClose }) => {
  const { palette } = useTheme()
  const { t } = useFavurTranslation()

  return isPersonsFilter && personsFilterOnClose ? (
    <DrawerHeader
      startContent={
        <IconButton onClick={personsFilterOnClose}>
          <ChevronLeft size={ICON_SIZE.medium} color={palette.text.secondary} />
        </IconButton>
      }
      middleContent={<Typography variant="subtitle1">{t('requestEmployeeData.single.title')}</Typography>}
      closeIconOnClick={personsFilterOnClose}
    />
  ) : (
    <DrawerHeader
      startContent={<Typography variant="subtitle1">{t('filter.title.header')}</Typography>}
      endContent={<Button variant="text">{t('filter.button.reset')}</Button>}
      closeIconOnClick={() => {}}
    />
  )
}

export default FiltersHeaders
