import React from 'react'
import { Button } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import RemovableChipGroup from 'redesign/components/molecules/ChipGroups/RemovableChipGroup'
import { Container, FooterContainer } from './styles'
import type { PersonFilterProps } from './types'
import usePersonsFilter from './usePersonsFilter'

const PersonsFilter: React.FC<PersonFilterProps> = ({ configuration }) => {
  const { t } = useFavurTranslation()
  const { selectedPersonsForChips, clearPersonListElement, openPersonsList, clearPersonList } = usePersonsFilter(
    configuration,
  )

  return (
    <Container>
      {selectedPersonsForChips.length > 0 ? (
        <RemovableChipGroup
          options={selectedPersonsForChips}
          onDelete={(id: number) => clearPersonListElement(configuration.name, id)}
        />
      ) : null}
      <FooterContainer>
        <Button variant="outlined" color="primary" size="medium" onClick={() => clearPersonList(configuration.name)}>
          {t('filter.persons.card.clearAll')}
        </Button>
        <Button variant="outlined" color="primary" size="medium" onClick={() => openPersonsList(configuration.name)}>
          {t('filter.persons.card.button')}
        </Button>
      </FooterContainer>
    </Container>
  )
}

export default PersonsFilter
