import React from 'react'
import { Typography } from '@mui/material'
import useFilterContext from 'components/Filter/context'
import useFavurTranslation from 'hooks/useFavurTranslation'
import FilterButton from 'redesign/components/atoms/Buttons/FilterButton'
import Header from 'redesign/components/organisms/Header'

const DocumentsHeader = () => {
  const { t } = useFavurTranslation()
  const headerTitle = t('page.documents.page.title')
  const { openFilterPage } = useFilterContext()

  return (
    <Header
      middleContent={<Typography variant="subtitle1">{headerTitle}</Typography>}
      endContent={<FilterButton onClick={openFilterPage} />}
    />
  )
}

export default DocumentsHeader
