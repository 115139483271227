import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import NavigationButtons from 'redesign/components/molecules/NavigationButtons'
import NavigationGroup from 'redesign/components/molecules/NavigationGroup'
import { MENU_CONTAINER_MOTION, PRIMARY_GROUP_MOTION, SECONDARY_GROUP_MOTION, TERTIARY_GROUP_MOTION } from './motions'
import { MenuContainer } from './styles'
import type { TNavigationMenuOptions } from './types'

const NavigationMenuOptions: React.FC<TNavigationMenuOptions> = ({
  toggleDrawer,
  primaryNavigation,
  secondaryNavigation,
  tertiaryNavigation,
}) => (
  <AnimatePresence>
    <MenuContainer data-testid="navigation-menu">
      <motion.div initial="hidden" animate="visible" variants={MENU_CONTAINER_MOTION}>
        <motion.div variants={PRIMARY_GROUP_MOTION}>
          <NavigationGroup toggleDrawer={toggleDrawer} navPoints={primaryNavigation} />
        </motion.div>
        <motion.div variants={SECONDARY_GROUP_MOTION}>
          <NavigationGroup toggleDrawer={toggleDrawer} navPoints={secondaryNavigation} />
        </motion.div>
        <motion.div variants={TERTIARY_GROUP_MOTION}>
          <NavigationButtons toggleDrawer={toggleDrawer} navPoints={tertiaryNavigation} />
        </motion.div>
      </motion.div>
    </MenuContainer>
  </AnimatePresence>
)

export default NavigationMenuOptions
