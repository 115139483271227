import { Box, styled } from '@mui/material'

export const Background = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isVisible' && prop !== 'isNative',
})<{ isVisible?: boolean; isNative?: boolean }>`
  position: absolute;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: calc(100vh - var(--ion-safe-area-bottom) - var(--ion-safe-area-top));
  z-index: 4;
  background-color: ${({ isVisible }) => (isVisible ? '#0f2d79' : 'transparent')};

  ${({ isNative, theme }) =>
    !isNative &&
    `
        height: calc(100% - ${theme.spacing(4.625)})
    `}
`

export const BottomScroll = styled(Box)`
  position: absolute;
  width: 100%;
  height: ${({ theme }) => theme.spacing(4.5)};
  background: linear-gradient(180deg, rgba(24, 51, 101, 0) 0%, rgba(255, 255, 255, 0.28) 100%);
  bottom: ${({ theme }) =>
    `calc(max(var(--ion-safe-area-bottom), ${theme.spacing(0.75)}) + ${theme.spacing(1.25)} + ${theme.spacing(
      1.5,
    )} + ${theme.spacing(0.75)} + ${theme.spacing(4)})`};
`
