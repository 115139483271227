import React, { useMemo, useRef } from 'react'
import { Fade } from '@mui/material'
import { AuthenticationKind } from 'constants/authentication'
import { isNative } from 'utils/platform'
import SideMenuHeader from '../../Headers/SideMenuHeader'
import NavigationFooter from '../NavigationFooter'
import NavigationMenuOptions from '../NavigationMenuOptions'
import { Background, BottomScroll } from './styles'
import type { NavigationMenuProps } from './types'

const NavigationMenu: React.FC<NavigationMenuProps> = ({
  auth,
  navigate,
  toggleDrawer,
  hideHamburger,
  showBackground,
  primaryNavigation,
  secondaryNavigation,
  tertiaryNavigation,
  userFirstName,
}) => {
  const loggedInAndShowHamburger = auth > AuthenticationKind.ANONYMOUS && !hideHamburger
  const scrollRef = useRef<HTMLDivElement>(null)

  const scroll = useMemo(
    () => navigate && (scrollRef.current?.scrollHeight ?? 0) - (scrollRef.current?.clientHeight ?? 0) > 0,
    [navigate],
  )

  return (
    <>
      {loggedInAndShowHamburger && (
        <Fade in={navigate} timeout={700}>
          <Background isVisible={showBackground} isNative={isNative()}>
            <SideMenuHeader userFirstName={userFirstName} />
            {scroll && <BottomScroll />}
            <NavigationMenuOptions
              toggleDrawer={toggleDrawer}
              primaryNavigation={primaryNavigation}
              secondaryNavigation={secondaryNavigation}
              tertiaryNavigation={tertiaryNavigation}
            />
            {isNative() && <NavigationFooter toggleDrawer={toggleDrawer} />}
          </Background>
        </Fade>
      )}
    </>
  )
}

export default NavigationMenu
