import React, { useState } from 'react'
import { useTheme } from '@mui/material'
import { CloseIcon } from 'redesign/components/atoms/Icon'
import type { NavigationMenuProps } from '../NavigationMenu/types'
import { ExitButton, Footer } from './styles'

const NavigationFooter: React.FC<Pick<NavigationMenuProps, 'toggleDrawer'>> = ({ toggleDrawer }) => {
  const theme = useTheme()
  const [isHovered, setIsHovered] = useState(false)

  return (
    <Footer>
      <ExitButton
        disableRipple
        onClick={toggleDrawer}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <CloseIcon color={isHovered ? theme.palette.common.white : theme.palette.common.navItemBase} />
      </ExitButton>
    </Footer>
  )
}

export default NavigationFooter
