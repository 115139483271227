import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import SearchInput from 'redesign/components/atoms/SearchInput'
import HeaderBlock from 'redesign/components/molecules/HeaderBlock'
import { getSortingLastName } from 'utils/person'
import { CardContainer, Checkbox, DrawerBody, DrawerContainer, SearchbarContainer } from './styles'
import type { DrawerProps } from './types'

const PersonFilterSelection: React.FC<DrawerProps> = ({ persons }) => {
  const { t } = useFavurTranslation()

  return (
    <DrawerContainer>
      <SearchbarContainer>
        <SearchInput label={t('filters.persons.search.label')} onChange={() => {}} />
      </SearchbarContainer>
      <DrawerBody>
        {persons &&
          persons.options.map((option) => (
            <CardContainer key={option.id}>
              <HeaderBlock component={<Checkbox size="small" />} title={getSortingLastName(option)} />
            </CardContainer>
          ))}
      </DrawerBody>
    </DrawerContainer>
  )
}

export default PersonFilterSelection
