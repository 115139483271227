import React, { useEffect, useState } from 'react'
import useFavurNavigation from 'hooks/useFavurNavigation'
import useUsername from 'hooks/useUsername'
import NavigationMenu from 'redesign/components/organisms/Navigation/NavigationMenu'
import { isNative } from 'utils/platform'
import type { TSideMenu } from './types'

const SideMenuNavigation: React.FC<TSideMenu> = ({
  auth,
  navigate,
  toggleDrawer,
  hideBackground,
  hideHamburger,
  hideWebToolbar,
}) => {
  const [showBackground, setShowBackground] = useState(false)
  const { primaryNavigation, secondaryNavigation, tertiaryNavigation } = useFavurNavigation()
  const { firstName } = useUsername()

  useEffect(() => {
    if (hideBackground) {
      setShowBackground(hideBackground && navigate)
    } else {
      setShowBackground(navigate)
    }
  }, [navigate, hideBackground, setShowBackground])

  if (!isNative() && hideWebToolbar) {
    return null
  }

  return (
    <NavigationMenu
      auth={auth}
      navigate={navigate}
      showBackground={showBackground}
      toggleDrawer={toggleDrawer}
      hideHamburger={hideHamburger}
      primaryNavigation={primaryNavigation}
      secondaryNavigation={secondaryNavigation}
      tertiaryNavigation={tertiaryNavigation}
      userFirstName={firstName}
    />
  )
}

export default SideMenuNavigation
