import React from 'react'
import Chip from 'redesign/components/atoms/Chip'
import { Container } from './styles'
import type { RemovableChipGroupProps } from './types'

const RemovableChipGroup: React.FC<RemovableChipGroupProps> = ({ options, onDelete }) => {
  return (
    <Container>
      {options.map(({ label, value }) => (
        <Chip key={value} label={label} onDelete={() => onDelete(value)} selected />
      ))}
    </Container>
  )
}

export default RemovableChipGroup
