import React from 'react'
import DialogService from 'components/Dialog/DialogService'
import OldNativeVersionDialog from 'components/OldNativeVersionDialog'
import UpdateApp from 'components/Splash/UpdateApp'
import DashboardHeader from 'redesign/components/organisms/Headers/DashboardHeader'
import DashboardContent from 'redesign/components/templates/DashboardContent'
import Page from 'redesign/components/templates/Page'
import { useDashboardPage } from './useDashboardPage'

const DashboardPage: React.FC = () => {
  const {
    favurPackages,
    header,
    isLoading,
    isManager,
    isNoOverrideNative,
    taskSection,
    shiftsSection,
    quickLinksSection,
  } = useDashboardPage()

  return (
    <UpdateApp>
      <Page paddingX={2} header={<DashboardHeader header={header} />}>
        <DialogService subscribed={['securityTimeout', 'securitySetup', 'pinReset', 'pinChange']} />
        <DashboardContent
          isLoading={isLoading}
          isManager={isManager}
          taskSection={taskSection}
          favurPackages={favurPackages}
          shiftsSection={shiftsSection}
          quickLinksSection={quickLinksSection}
        />
      </Page>
      {isNoOverrideNative && <OldNativeVersionDialog showOnlyOnce />}
    </UpdateApp>
  )
}

export default DashboardPage
