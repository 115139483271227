import React, { useMemo } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import useFilterContext from 'components/Filter/context'
import type { PersonsT } from 'components/Filter/types'
import FullScreenModal from 'redesign/components/organisms/FullScreenModal'
import FiltersBody from './FiltersBody'
import FiltersFooter from './FiltersFooter'
import FiltersHeaders from './FiltersHeaders'
import type { FiltersProps } from './types'

const Filters: React.FC<FiltersProps> = ({ rootId }) => {
  const {
    closeFilterPage,
    isFilterPageOpen,
    getPersonsListSelected,
    getConfiguration,
    cancelPersonsList,
  } = useFilterContext()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const configuration = useMemo(() => getConfiguration(), [getConfiguration])
  const personsListSelected = useMemo(() => getPersonsListSelected(), [getPersonsListSelected])
  const isPersonsListSelected = Boolean(personsListSelected)
  const personsOption = useMemo(
    () =>
      personsListSelected
        ? (configuration.find((option) => option.name === personsListSelected) as PersonsT)
        : undefined,
    [configuration, personsListSelected],
  )

  return (
    <FullScreenModal
      disableDrag
      isSmallScreen={isSmallScreen}
      rootId={rootId}
      onClose={closeFilterPage}
      isOpen={isFilterPageOpen()}
      header={
        <FiltersHeaders
          isPersonsFilter={isPersonsListSelected}
          personsFilterOnClose={() => cancelPersonsList(personsOption?.name ?? '')}
        />
      }
      footer={<FiltersFooter isPersonsFilter={isPersonsListSelected} />}
    >
      <FiltersBody />
    </FullScreenModal>
  )
}

export default Filters
