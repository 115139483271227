import { useMemo } from 'react'
import useFilterContext from 'components/Filter/context'
import type { PersonsT } from 'components/Filter/types'
import type { RemovableChipOption } from 'redesign/components/molecules/ChipGroups/RemovableChipGroup/types'
import type { PersonT } from 'types'
import { getUserName } from 'utils/person'
import type { PersonFilterProps } from './types'

const usePersonsFilter = (configuration: PersonFilterProps['configuration']) => {
  const {
    getValue,
    clearPersonListElement,
    openPersonsList,
    clearPersonList,
    getPersonsListSelected,
    getConfiguration,
    cancelPersonsList,
  } = useFilterContext()

  const selectedPersonIds = useMemo(() => (getValue(configuration.name) as string[]) || [], [
    getValue,
    configuration.name,
  ])
  const selectedPersons = useMemo(
    () =>
      selectedPersonIds
        .map((personId) => configuration.options.find((pp) => `${pp.id}` === personId))
        .filter((person): person is PersonT => person !== undefined),
    [configuration.options, selectedPersonIds],
  )
  const selectedPersonsForChips: RemovableChipOption[] = useMemo(
    () =>
      selectedPersons.map((person) => ({
        label: getUserName(person),
        value: person.id ?? 0,
      })),
    [selectedPersons],
  )
  const personsListSelected = getPersonsListSelected()
  const personListOption = useMemo(
    () => getConfiguration().find((option) => option.name === personsListSelected) as PersonsT,
    [getConfiguration, personsListSelected],
  )

  return {
    selectedPersonsForChips,
    clearPersonListElement,
    openPersonsList,
    clearPersonList,
    cancelPersonsList,
    personsListSelected,
    personListOption,
  }
}

export default usePersonsFilter
